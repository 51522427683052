<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <el-form
      :model="dataForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="普通本专科" prop="generalCollege">
        <el-input
          v-model="dataForm.generalCollege"
          autocomplete="off"
          placeholder="请输入普通本专科..."
          :rows="4"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="成人教育" prop="adultEducation">
        <el-input
          v-model="dataForm.adultEducation"
          placeholder="请输入成人教育..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="研究生" prop="postgraduate">
        <el-input
          v-model="dataForm.postgraduate"
          placeholder="请输入研究生..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="网络教育" prop="networkEducation">
        <el-input
          v-model="dataForm.networkEducation"
          placeholder="请输入网络教育..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="开放教育" prop="openEducation">
        <el-input
          v-model="dataForm.openEducation"
          placeholder="请输入开放教育..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="普通本科" prop="ordinaryUndergraduate">
        <el-input
          v-model="dataForm.ordinaryUndergraduate"
          placeholder="请输入普通本科..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="普通专科" prop="generalSpecialty">
        <el-input
          v-model="dataForm.generalSpecialty"
          placeholder="请输入普通专科..."
          autocomplete="off"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>

    <div style="display: flex; justify-content: flex-end">
      <el-button
        style="width: 120px; margin-bottom: 20px"
        type="primary"
        @click="submitForm()"
        :loading="submitLoding"
        size="small"
        v-if="submitButton"
        >保 存
      </el-button>
    </div>
  </div>
</template>

<script>
import { checkSchoolEduType, postSchoolEduType } from "@/api/school";
import { queryPageButton } from "@/api/permission";

export default {
  name: "authorization",
  data() {
    var validateGenera = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入普通本专科"));
      } else {
        callback();
      }
    };

    var validateAdult = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入成人教育"));
      } else {
        callback();
      }
    };

    var validateuserPost = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入研究生"));
      } else {
        callback();
      }
    };
    var validateBetwork = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入网络教育"));
      } else {
        callback();
      }
    };
    var validateOpen = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入开放教育"));
      } else {
        callback();
      }
    };
    var validategraduate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入普通本科"));
      } else {
        callback();
      }
    };
    var validateSpecialty = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入普通专科"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        generalCollege: [
          {
            validator: validateGenera,
            trigger: "blur",
          },
        ],
        adultEducation: [
          {
            validator: validateAdult,
            trigger: "blur",
          },
        ],
        postgraduate: [
          {
            validator: validateuserPost,
            trigger: "blur",
          },
        ],
        networkEducation: [
          {
            validator: validateBetwork,
            trigger: "blur",
          },
        ],
        openEducation: [
          {
            validator: validateOpen,
            trigger: "blur",
          },
        ],
        ordinaryUndergraduate: [
          {
            validator: validategraduate,
            trigger: "blur",
          },
        ],
        generalSpecialty: [
          {
            validator: validateSpecialty,
            trigger: "blur",
          },
        ],
      },
      submitLoding: false,
      dataForm: {},
      //权限按钮
      submitButton:false
    };
  },
  created() {
    this.queryButton();
    this.getParam();
  },
  methods: {
    queryButton() {
      const data = 139;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "submitButton") {
            this.submitButton = true;
          }
        }
      });
    },
    //获取授权信息
    getParam() {
      checkSchoolEduType().then((resp) => {
        if (resp.code == "0") {
          this.dataForm = resp.data;
        }
      });
    },
    //修改提交
    submitForm() {
      this.submitLoding = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          postSchoolEduType(this.dataForm)
            .then((resp) => {
              this.submitLoding = false;
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
            })
            .catch((e) => {
              this.submitLoding = false;
              console.log(e.responseText);
            });
        } else {
          this.submitLoding = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
